

 .oper_items{
     text-decoration: none;
     color: black;
     width: 100%;
     display: block;
     padding: 10px;
     border-radius: 5px;
     box-sizing: border-box;
 }
 .sidebar{
     width: 300px;
  
 }
 .oper_items:hover{
     color: blue;
 }

 