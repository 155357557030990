.offer-letter-container {
    background-color: #f8f9fa;
    border-radius: 15px;
    padding: 20px;
    margin: 20px auto;
  }
  
  .offer-letter-content {
    border: none;
    padding: 20px;
  }
  
  .orcadehub-logo {
    margin-bottom: 20px;
  }
  
  .offer-letter-content h2,
  .offer-letter-content h3,
  .offer-letter-content h4 {
    margin-bottom: 20px;
  }
  
  .offer-letter-content p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .offer-letter-content .text-right {
    text-align: right;
  }
  
  .offer-letter-content .d-flex {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
  }
  