.testimonials {
  background-color: #f4f4f4;
  padding: 60px 0 120px 0;
}
.testimonials .info h3 {
  font-weight: 700;
  font-size: 32px;
}
.testimonials .swiper {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 200px;
  position: relative;
  margin: 30px;
}
.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  border: 6px solid #f4f4f4;
  margin-right: 10px;
}
.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  margin: 0;
  color: rgba(33, 37, 41, 0.05);
}
.testimonials .testimonial-item .stars {
  margin: 10px 0;
}
.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}
.testimonials .testimonial-item i {
  color: rgba(232, 69, 69, 0.04);
  font-size: 26px;
  line-height: 0;
}
.testimonials .testimonial-item .right-icon {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .left-icon {
  display: inline-block;
  right: -5px;
  position: relative;
  transform: scale(-1, -1);
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}
.testimonials .swiper-wrapper {
  height: auto;
}
.testimonials .swiper-pagination {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: rgba(33, 37, 41, 0.15);
  opacity: 1;
  border: none;
}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e84545;
}
@media (max-width: 767px) {
  .testimonials .testimonial-item {
    margin: 15px;
  }
}
