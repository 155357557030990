/* Services.css */

.service-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card:hover {
    transform: scale(1.05); /* Zoom effect */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Thick black shadow */
    border: 2px solid #000; /* Highlight border */
  }
  
  .service-card img {
    transition: transform 0.3s ease;
  }
  
  .service-card:hover img {
    transform: scale(1.1); /* Zoom in on image */
  }
  