.header{
  padding:0 120px;
  margin: 0;
  overflow-x: hidden;
  
}
.header_logo img{
  width: 70px;
}
Link{
  text-decoration: none;
}
.call_mail:hover{
  text-decoration: underline;
}
.social_icons:hover{
  color: orange;
}
.first-nav{
  margin-right: -10px;
}
.sec-navbar{
  margin-left: -20px;
}
.second_nav .nav-link{
  color: rgb(7, 7, 90);
} 
.second_nav .nav-link:hover{
  color: orange;
}
.second_nav .nav-link.active{
  color: orange !important;
}

.toggle:focus{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
@media (min-width: 1000px){
  #submenu, #submenu2, #submenu3 {
      list-style: none;
      position: absolute;
      width: 220px;
      background-color: rgb(7, 7, 90);
      margin-top: 20px;
      padding: 0;
      opacity: 0;
      z-index: -999;
      transition: all ease-in-out .5s;
  }

  #submenu .nav-link ,  #submenu2 .nav-link,   #submenu3 .nav-link{
      color: white;
      font-weight: 400;
      
  }

  #submenu .nav-link:hover,  #submenu2 .nav-link:hover, #submenu3 .nav-link:hover {
      color: orange;
      background-color: rgb(64, 64, 121);
  }

  .dropdownmenu:hover #submenu,  .dropdownmenu:hover #submenu2, .dropdownmenu:hover #submenu3 {
      z-index: 99;
      opacity: 1;
  }
  .drop-icon{
      visibility: hidden;
      margin-right: -10px;
  }
}
@media (max-width: 1000px) {
  .header .border-bottom {
      border-bottom: hidden !important;
  }

  .header .header_logo {
      margin-left: -115px;
      margin-top: 10px;
  }

  .header_logo_offcanvas {
      border-bottom: .5px solid rgb(223, 226, 228);
  }

  .header_logo img {
      width: 30px;
  }

  .header_logo .nav-brand {
      font-size: larger !important;
  }

  .first-nav {
      display: none;
      visibility: hidden;
  }
  .dropdownmenu p{
      margin-bottom: -3px !important;
      border-bottom: none !important;
  }
  .second_nav {
      margin-top: -10px;
  }
  .second_nav .nav-link {
      padding-left: 20px;
      border-bottom: .5px solid rgb(228, 231, 235);
      margin-top: -18px;
  }
  .offcanvas-nav{
      height: min-content !important;
  }
  .register{
      margin-bottom: -30px;
  }
  #submenu , #submenu2, #submenu3{
      list-style: none;
  }
  #submenu .nav-link, #submenu2 .nav-link,
  #submenu3 .nav-link{
      padding-top: 20px;
      border-bottom: none;
      margin-bottom: -20px !important;
  }
  .dropdownmenu .sidebar-link{
      margin: 5px 0;
      padding-left: 40px;
  }
  .sidebar-dropdown .sidebar-item{
      margin: 5px 0;
      padding-left: 60px;
  }
  .toggle {
      position: fixed;
      right: 10px;
      top: 15px;
  }
}
@media (max-width: 570px) {
  .toggle {
      position: fixed;
      right: 10px;
      top: 15px;
  }

  .header_logo {
      margin-left:0;
  }}