#courses .accordion-body {
    height: auto; /* Adjust this if needed to control the height */
    max-height: 500px; /* Example value to double the height */
    overflow: auto; /* Add scroll if content overflows */
  }
  
  /* Optional: Add padding to headers for better spacing */
  #courses .accordion-header {
    padding: 15px 20px;
  }
  